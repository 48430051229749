import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TopBarComponent } from "../components/top-bar/top-bar.component";
import { SideBarComponent } from "../components/side-bar/side-bar.component";
import { MaterialModule } from "./material.module";
import { RouterModule } from "@angular/router";
import { OnBoardingSliderComponent } from "../components/on-boarding-slider/on-boarding-slider.component";
import { ShareYourExperienceModalComponent } from "../components/share-your-experience-modal/share-your-experience-modal.component";
import { InputRatingComponent } from "../components/input-rating/input-rating.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GenerateReportModalComponent } from "../components/generate-report-modal/generate-report-modal.component";
import { ShareFeedbackModalComponent } from "../components/share-feedback-modal/share-feedback-modal.component";
import { AccountBalanceModalComponent } from "../components/account-balance-modal/account-balance-modal.component";
import { MyInvestmentCardComponent } from "../components/my-investment-card/my-investment-card.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { NgxShimmerLoadingModule } from "ngx-shimmer-loading";
import { InvestmentDetailModalComponent } from "../components/investment-detail-modal/investment-detail-modal.component";
import { JoinUsToInviteModalComponent } from "../components/join-us-to-invite-modal/join-us-to-invite-modal.component";
import { InvestmentSuccessfulModalComponent } from "../components/investment-successful-modal/investment-successful-modal.component";
import { InvestmentFilterComponent } from "../components/investment-filter/investment-filter.component";
import { InvestmentFailedModalComponent } from "../components/investment-failed-modal/investment-failed-modal.component";
import { WithdrawInvestmentModalComponent } from "../components/withdraw-investment-modal/withdraw-investment-modal.component";
import { NumericInputDirective } from "../directives/numeric-input.directive";
import { ShowWarningModalComponent } from "../components/show-warning-modal/show-warning-modal.component";
import { AlphanumericWithSpacesOnlyDirective } from "../directives/alpha-numeric-with-space-directive";
import { AlphanumericOnlyDirective } from "../directives/alpha-numeric-directive";
import { DematAccountDirective } from "../directives/demat-account.directive";
import { CustomDatePipe } from "../pipes/custom-date.pipe";
import { AssetCardV2Component } from "../components/asset-card-v2/asset-card-v2.component";
import { UnitsBlockedModalComponent } from "../components/units-blocked-modal/units-blocked-modal.component";
import { UserInterestInputModalComponent } from "../components/user-interest-input-modal/user-interest-input-modal.component";
import { SafeUrlPipe } from "../pipes/safe-url.pipe";
import { PreRedirectLinkModalComponent } from "../components/pre-redirect-link-modal/pre-redirect-link-modal.component";

@NgModule({
  declarations: [
    TopBarComponent,
    SideBarComponent,
    OnBoardingSliderComponent,
    ShareYourExperienceModalComponent,
    InputRatingComponent,
    GenerateReportModalComponent,
    InvestmentFilterComponent,
    ShareFeedbackModalComponent,
    AccountBalanceModalComponent,
    MyInvestmentCardComponent,
    InvestmentDetailModalComponent,
    JoinUsToInviteModalComponent,
    InvestmentSuccessfulModalComponent,
    InvestmentFailedModalComponent,
    WithdrawInvestmentModalComponent,
    UnitsBlockedModalComponent,
    NumericInputDirective,
    AlphanumericWithSpacesOnlyDirective,
    AlphanumericOnlyDirective,
    DematAccountDirective,
    ShowWarningModalComponent,
    CustomDatePipe,
    SafeUrlPipe,
    AssetCardV2Component,
    UserInterestInputModalComponent,
    PreRedirectLinkModalComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    InfiniteScrollModule,
    NgxShimmerLoadingModule,
  ],
  exports: [
    CommonModule,
    TopBarComponent,
    SideBarComponent,
    OnBoardingSliderComponent,
    ShareYourExperienceModalComponent,
    InputRatingComponent,
    GenerateReportModalComponent,
    InvestmentFilterComponent,
    ShareFeedbackModalComponent,
    AccountBalanceModalComponent,
    MyInvestmentCardComponent,
    AssetCardV2Component,
    UnitsBlockedModalComponent,
    FormsModule,
    ReactiveFormsModule,
    NgxShimmerLoadingModule,
    InfiniteScrollModule,
    InvestmentSuccessfulModalComponent,
    InvestmentFailedModalComponent,
    NumericInputDirective,
    AlphanumericWithSpacesOnlyDirective,
    DematAccountDirective,
    AlphanumericOnlyDirective,
    CustomDatePipe,
    SafeUrlPipe,
    UserInterestInputModalComponent,
    MaterialModule,
    PreRedirectLinkModalComponent,
  ],
})
export class SharedModule {}
