import { Directive, ElementRef, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
  selector: "[alphanumericWithSpacesOnly]",
})
export class AlphanumericWithSpacesOnlyDirective {
  constructor(
    private el: ElementRef,
    private control: NgControl
  ) {}

  @HostListener("input", ["$event"])
  onInput(event: Event): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    let inputValue = inputElement.value;
    let sanitizedValue = inputValue.replace(/[^a-zA-Z ]/g, "");
    if (sanitizedValue.length > 0 && sanitizedValue[0] === " ") {
      sanitizedValue = sanitizedValue.trimStart();
    }
    if (this.control) {
      this.control.control?.setValue(sanitizedValue, { emitEvent: false });
    }
    inputElement.value = sanitizedValue;
  }
}
