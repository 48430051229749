import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IGenericResponse } from "src/models/genericModels";
import { CommonService } from "src/services/common.service";
import { TestimonialService } from "src/services/testimonial.service";

@Component({
  selector: "app-share-your-experience-modal",
  templateUrl: "./share-your-experience-modal.component.html",
  styleUrls: ["./share-your-experience-modal.component.scss"],
})
export class ShareYourExperienceModalComponent {
  rating: number = 5;
  reviewText: string = "";
  isSubmitting: boolean = false;
  submitted: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ShareYourExperienceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private testimonialService: TestimonialService,
    private commonService: CommonService
  ) {}

  changeRating(rate: any) {
    this.rating = rate;
  }

  closeModal(refresh: boolean) {
    this.dialogRef.close({ refresh: refresh });
  }

  submit() {
    if (this.reviewText.length && this.rating) {
      this.isSubmitting = true;
      let body: any = {
        description: this.reviewText,
        rating: this.rating,
      };

      this.testimonialService.addTestimonial(body).subscribe({
        next: (res: IGenericResponse) => {
          this.isSubmitting = false;
          this.submitted = true;
          console.log("Add Testimonial Response:", res);
        },
        error: (err: any) => {
          this.isSubmitting = false;
          this.commonService.openSnackbar(err.error.message);
          console.error("Add Testimonial Error:", err);
        },
      });
    }
  }
}
