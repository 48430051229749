import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UserInterestLoggedOutBody } from "src/models/featureFlag";
import { CommonService } from "src/services/common.service";
import { UserInterestService } from "src/services/user-interest.service";

@Component({
  selector: "app-user-interest-input-modal",
  templateUrl: "./user-interest-input-modal.component.html",
  styleUrls: ["./user-interest-input-modal.component.scss"],
})
export class UserInterestInputModalComponent {
  interestForm = new FormGroup({
    key: new FormControl("", [Validators.required]),
    name: new FormControl("", [Validators.required, Validators.minLength(3)]),
    phone_number: new FormControl("", [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(18),
      Validators.pattern("^[0-9]*$"),
    ]),
  });
  loading: boolean = false;

  constructor(
    private userInterestService: UserInterestService,
    private commonService: CommonService,
    public dialogRef: MatDialogRef<UserInterestInputModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.interestForm.controls["key"].setValue(data.featureName);
  }

  submit() {
    if (this.interestForm.valid) {
      this.loading = true;
      this.userInterestService
        .addUserInterestLoggedOut(
          this.interestForm.value as UserInterestLoggedOutBody
        )
        .subscribe({
          next: (res) => {
            this.loading = false;
            this.interestForm.reset();
            this.dialogRef.close(true);
            this.commonService.openSnackbar(res.message);
            console.log("User Interest Logged Out:", res);
          },
          error: (err) => {
            this.loading = false;
            console.error("User Interest Logged Out Error:", err);
          },
        });
    }
  }
}
