export const environment = {
  production: false,
  baseUrl: "https://qa-apis.finzace.com/",
  firebaseConfig: {
    apiKey: "AIzaSyAwFVb1mou4mg0LncLD-iYPnN01kIsJHbQ",
    authDomain: "ics-fcm.firebaseapp.com",
    projectId: "ics-fcm",
    storageBucket: "ics-fcm.appspot.com",
    messagingSenderId: "223865687176",
    appId: "1:223865687176:web:c8e28b64347e51dd2d233d",
    measurementId: "G-1MMZWF51TS",
  },
  redirectionUrl: "https://test.com:4200/check-payment-status",
  pixelId: "1281000326197938",
};
