import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-input-rating",
  templateUrl: "./input-rating.component.html",
  styleUrls: ["./input-rating.component.scss"],
})
export class InputRatingComponent implements OnInit {
  stars: boolean[] = Array(5).fill(false);
  @Input() rating: number = 0;
  @Output() rate = new EventEmitter();
  @Input() inputRating: boolean = true;
  @Input() freeze: boolean = false;
  hoverPosition = -1;

  constructor() {}

  ngOnInit(): void {}

  selectStar() {
    this.rate.emit(this.rating);
  }
}
