<div class="top-bar">
  <div class="d-flex flex-row align-items-center">
    <button class="menu-button" matRipple (click)="toggleNavbar()">
      <mat-icon class="menu-icon">menu</mat-icon>
    </button>
    <img src="/assets/image/logo.svg" width="43" height="40" alt="logo" />
    <span class="page-title">{{ title }}</span>
  </div>
  <button
    type="submit"
    class="get-started-btn"
    *ngIf="!userAuthService.accessToken"
    matRipple
    routerLink="/login"
  >
    Get Started
  </button>
  <div
    class="d-flex flex-row align-items-center"
    *ngIf="userAuthService.accessToken"
  >
    <!-- <div
      class="d-flex flex-row align-items-center cursor-pointer"
      (click)="openAccountBalanceModal()"
    >
      <img src="../../../../assets/icon/wallet-icon.svg" class="wallet-icon" />
      <span class="wallet-amount">₹{{ balance | number: "1.0-2" }}</span>
    </div> -->
    <img
      [src]="
        userAuthService.user.profile_url
          ? userAuthService.user.profile_url
          : '../../../../assets/image/static/user-pic.svg'
      "
      class="profile-pic"
      [matMenuTriggerFor]="myAccount"
    />
    <button type="button" class="profile-btn" [matMenuTriggerFor]="myAccount">
      <span class="username">{{ userAuthService.user.name }}</span>
      <img src="../../../../assets/icon/arrow_up.svg" class="down-arrow" />
    </button>
  </div>
</div>
<mat-menu #myAccount="matMenu" class="my-account-dropdown">
  <div
    class="user-info-card"
    matRipple
    [routerLink]="['/profile']"
    [queryParams]="{ tab: 'ACCOUNT_DETAILS' }"
  >
    <img
      [src]="
        userAuthService.user.profile_url
          ? userAuthService.user.profile_url
          : '../../../../assets/image/static/user-pic.svg'
      "
      class="profile-pic"
    />
    <div class="user-info">
      <span class="username">{{ userAuthService.user.name }}</span>
      <span class="email">{{ userAuthService.user.email }}</span>
    </div>
  </div>
  <button
    type="submit"
    class="menu-item"
    matRipple
    [routerLink]="['/profile']"
    [queryParams]="{ tab: 'ACCOUNT_DETAILS' }"
  >
    <span class="menu-icon"></span>
    <span class="btn-text">Account Details</span>
  </button>
  <span class="divider"></span>
  <button
    type="submit"
    class="menu-item"
    matRipple
    [routerLink]="['/profile']"
    [queryParams]="{ tab: 'CHANGE_PASSWORD' }"
  >
    <span class="menu-icon"></span>
    <span class="btn-text">Change Password</span>
  </button>
  <span class="divider"></span>
  <button
    type="submit"
    class="menu-item"
    matRipple
    [routerLink]="['/profile']"
    [queryParams]="{ tab: 'ACCOUNT_DETAILS' }"
  >
    <img src="../../../../assets/icon/settings-icon.svg" class="menu-icon" />
    <span class="btn-text">Settings</span>
  </button>
  <span class="divider"></span>
  <button
    type="submit"
    class="menu-item"
    matRipple
    [routerLink]="['/profile']"
    [queryParams]="{ tab: 'SUPPORT_REQUEST' }"
  >
    <img src="../../../../assets/icon/support-icon.svg" class="menu-icon" />
    <span class="btn-text">Support Request</span>
  </button>
  <span class="divider"></span>
  <button type="submit" class="menu-item" matRipple (click)="logout()">
    <img src="../../../../assets/icon/logout-icon.svg" class="menu-icon" />
    <span class="btn-text">Logout</span>
  </button>
</mat-menu>
