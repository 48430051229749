import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[numericInput]",
})
export class NumericInputDirective {
  @Input("numericInput") maxLength: number = 6;
  constructor(private el: ElementRef) {}

  @HostListener("input", ["$event"]) onInput(event: KeyboardEvent): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    let inputValue = inputElement.value;

    inputValue = inputValue.replace(/[^0-9]/g, "");

    if (inputValue.length > this.maxLength) {
      inputValue = inputValue.slice(0, this.maxLength);
    }

    inputElement.value = inputValue;
  }
}
