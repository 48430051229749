import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { IGenericResponse } from "src/models/genericModels";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  constructor(private http: HttpClient) {}

  getReport(
    type: string,
    start_date: string | null,
    end_date: string | null,
    email_reports: boolean
  ): Observable<IGenericResponse | Blob> {
    const token = `Token ${localStorage.getItem("authToken")}`;
    const url = `${environment.baseUrl}apis/investor/v1/reports/?start_date=${start_date}&end_date=${end_date}&type=${type}&email_reports=${email_reports}`;
    let httpOptions;
    if (email_reports) {
      httpOptions = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        responseType: "blob" as "json",
      };
    }
    return this.http.get<IGenericResponse | Blob>(url, httpOptions);
  }
}
