<div class="modal-container">
  <img
    class="investment-successfull-image"
    src="../../../../assets/image/Payment Successful Brand Colors.svg"
    alt="investment successfull"
  />
  <div class="title">Investment Successful</div>

  <button class="close-btn" matRipple (click)="closeModal()">
    <img
      class="vector"
      src="../../../../assets/image/vector.svg"
      alt="close-icon"
    />
  </button>
</div>
