import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { IGenericResponse } from "src/models/genericModels";
import {
  MySmartSaverInvestmentList,
  InvestmentSmartSaverStatusResponse,
  InvestSmartSaverTenuredV2Response,
  PortfolioResponse,
} from "src/models/smartSaverModels";
import { WalletTransactionsResponse } from "src/models/transaction";

@Injectable({
  providedIn: "root",
})
export class SmartSaverService {
  constructor(private http: HttpClient) {}

  fetchMyPortfolio() {
    const token = `Token ${localStorage.getItem("authToken")}`;
    const url = `${environment.baseUrl}apis/investor/smart-saver/v1/portfolio/`;
    let httpOptions = { headers: new HttpHeaders({ Authorization: token }) };
    return this.http.get<PortfolioResponse>(url, httpOptions);
  }

  investMoney(body: any): Observable<IGenericResponse> {
    const token = `Token ${localStorage.getItem("authToken")}`;
    const url = `${environment.baseUrl}apis/investor/smart-saver/v1/`;
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: token }),
    };
    return this.http.post<IGenericResponse>(url, body, httpOptions);
  }

  investMoneyTenuredV1(body: any): Observable<IGenericResponse> {
    const token = `Token ${localStorage.getItem("authToken")}`;
    const url = `${environment.baseUrl}apis/investor/smart-saver/v1/tenured/`;
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: token }),
    };
    return this.http.post<IGenericResponse>(url, body, httpOptions);
  }

  investMoneyTenuredV2(
    body: any
  ): Observable<InvestSmartSaverTenuredV2Response> {
    const token = `Token ${localStorage.getItem("authToken")}`;
    const url = `${environment.baseUrl}apis/investor/smart-saver/v2/tenured/`;
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: token }),
    };
    return this.http.post<InvestSmartSaverTenuredV2Response>(
      url,
      body,
      httpOptions
    );
  }

  fetchTenureList(): Observable<any> {
    const token = `Token ${localStorage.getItem("authToken")}`;
    const url = `${environment.baseUrl}apis/investor/smart-saver/v1/tenured/`;
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: token }),
    };
    return this.http.get<any>(url, httpOptions);
  }

  withdrawMoney(body: any): Observable<IGenericResponse> {
    const token = `Token ${localStorage.getItem("authToken")}`;
    const url = `${environment.baseUrl}apis/investor/smart-saver/v1/withdraw/`;
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: token }),
    };
    return this.http.post<IGenericResponse>(url, body, httpOptions);
  }

  fetchTransaction() {
    const token = `Token ${localStorage.getItem("authToken")}`;
    const url = `${environment.baseUrl}apis/investor/smart-saver/v1/transactions/`;
    let httpOptions = { headers: new HttpHeaders({ Authorization: token }) };
    return this.http.get<WalletTransactionsResponse>(url, httpOptions);
  }

  fetchUserInvestments(
    offset: number,
    limit: number
  ): Observable<MySmartSaverInvestmentList> {
    const token = `Token ${localStorage.getItem("authToken")}`;
    const url = `${environment.baseUrl}apis/investor/smart-saver/v1/?offset=${offset}&limit=${limit}`;
    let httpOptions = { headers: new HttpHeaders({ Authorization: token }) };
    return this.http.get<MySmartSaverInvestmentList>(url, httpOptions);
  }

  smartSaverInvestmentStatus(
    orderId: string
  ): Observable<InvestmentSmartSaverStatusResponse> {
    const token = `Token ${localStorage.getItem("authToken")}`;
    const url = `${environment.baseUrl}apis/investor/smart-saver/v2/tenured/status/?order_id=${orderId}`;
    let httpOptions = { headers: new HttpHeaders({ Authorization: token }) };
    return this.http.get<InvestmentSmartSaverStatusResponse>(url, httpOptions);
  }
}
