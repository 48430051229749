import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Asset } from "src/models/asset";
import { Investment } from "src/models/investment";
import { SmartInvestment } from "src/models/smartInvest";

@Component({
  selector: "app-asset-card-v2",
  templateUrl: "./asset-card-v2.component.html",
  styleUrls: ["./asset-card-v2.component.scss"],
})
export class AssetCardV2Component {
  @Input() investment!: SmartInvestment;
  @Input() asset!: Asset;

  constructor(private router: Router) {}

  navigateToAssetDetailPage(asset: Asset) {
    this.router.navigate(["/asset-detail", asset.id], {
      queryParams: { p: asset.partner.id },
    });
  }
}
