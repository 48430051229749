import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";

@Component({
  selector: "app-join-us-to-invite-modal",
  templateUrl: "./join-us-to-invite-modal.component.html",
  styleUrls: ["./join-us-to-invite-modal.component.scss"],
})
export class JoinUsToInviteModalComponent {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<JoinUsToInviteModalComponent>
  ) {}

  ngOnInit() {}

  redirectToLogin() {
    this.router.navigate(["login"]);
    this.closeModal();
  }
  closeModal() {
    this.dialogRef.close();
  }
}
