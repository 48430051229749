import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-withdrawal-request-received-modal",
  templateUrl: "./withdrawal-request-received-modal.component.html",
  styleUrls: ["./withdrawal-request-received-modal.component.scss"],
})
export class WithdrawalRequestReceivedModalComponent {
  constructor(
    public dialogRef: MatDialogRef<WithdrawalRequestReceivedModalComponent>
  ) {}

  ngOnInit() {}

  closeModal() {
    this.dialogRef.close();
  }
}
