import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";

@Component({
  selector: "app-investment-filter",
  templateUrl: "./investment-filter.component.html",
  styleUrls: ["./investment-filter.component.scss"],
})
export class InvestmentFilterComponent {
  @Output() close = new EventEmitter();
  checkboxValues: any[] = [
    { value: "Matured", status: false },
    { value: "Maturing in 1 Month", status: false },
    { value: "Maturing in a Quarter", status: false },
    { value: "Maturing in a Year", status: false },
  ];
  sliderValue1: number = 20;
  sliderValue2: number = 20;

  constructor(private router: Router) {}

  clearFilter() {
    this.closeModal();
  }

  submitFilter() {
    this.closeModal();
  }

  closeModal() {
    this.close.emit(true);
  }
}
