<div class="rating-container">
  <div class="rating-row rating">
    <div
      *ngFor="let star of stars; let i = index"
      (mouseover)="hoverPosition = i + 1"
      (mouseleave)="hoverPosition = -1"
    >
      <input
        class="d-none"
        type="radio"
        name="rating"
        id="rating-{{ i + 1 }}"
        [value]="i + 1"
        [(ngModel)]="rating"
        (change)="selectStar()"
        [disabled]="freeze"
      />
      <label class="star-label" for="rating-{{ i + 1 }}">
        <mat-icon
          class="star-icon"
          [ngClass]="{ active: i < rating, 'star-hover': i < hoverPosition }"
          >star</mat-icon
        >
      </label>
    </div>
  </div>
</div>
