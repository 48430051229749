import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/services/auth.guard";
import { NoAuthGuard } from "src/services/no-auth.guard";
import { HomeComponent } from "./pages/home/home.component";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginModule),
    canActivate: [NoAuthGuard],
  },
  {
    path: "check-payment-status",
    loadChildren: () =>
      import("./pages/check-payment-status/check-payment-status.module").then(
        (m) => m.CheckPaymentStatusModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "signup",
    loadChildren: () =>
      import("./pages/signup/signup.module").then((m) => m.SignupModule),
    canActivate: [NoAuthGuard],
  },
  {
    path: "password-recovery",
    loadChildren: () =>
      import("./pages/password-recovery/password-recovery.module").then(
        (m) => m.PasswordRecoveryModule
      ),
    canActivate: [NoAuthGuard],
  },
  {
    path: "create-new-password",
    loadChildren: () =>
      import("./pages/create-new-password/create-new-password.module").then(
        (m) => m.CreateNewPasswordModule
      ),
    canActivate: [NoAuthGuard],
  },
  {
    path: "terms-of-service",
    loadChildren: () =>
      import("./pages/terms-of-service/terms-of-service.module").then(
        (m) => m.TermsOfServiceModule
      ),
  },
  {
    path: "registration-fee-page",
    loadChildren: () =>
      import("./pages/registration-fee-page/registration-fee-page.module").then(
        (m) => m.RegistrationFeePageModule
      ),
  },
  {
    path: "registration-fee-page?view=auth-view",
    loadChildren: () =>
      import("./pages/registration-fee-page/registration-fee-page.module").then(
        (m) => m.RegistrationFeePageModule
      ),
  },
  {
    path: "bank-detail",
    loadChildren: () =>
      import("./pages/bank-acc-detail/bank-acc-detail.module").then(
        (m) => m.BankAccDetailModule
      ),
  },
  {
    path: "address-detail",
    loadChildren: () =>
      import("./pages/address-details/address-details.module").then(
        (m) => m.AddressDetailsModule
      ),
  },
  {
    path: "user-profile",
    loadChildren: () =>
      import("./pages/user-profile-form/user-profile-form.module").then(
        (m) => m.UserProfileFormModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "home",
    loadChildren: () =>
      import("./pages/home/home.module").then((m) => m.HomeModule),
    component: HomeComponent,
  },
  {
    path: "invest",
    loadChildren: () =>
      import("./pages/invest-now/invest-now.module").then(
        (m) => m.InvestNowModule
      ),
  },
  {
    path: "return-calculator",
    loadChildren: () =>
      import("./pages/return-calculator/return-calculator.module").then(
        (m) => m.ReturnCalculatorModule
      ),
  },
  {
    path: "asset-list",
    loadChildren: () =>
      import("./pages/asset-listing/asset-listing.module").then(
        (m) => m.AssetListingModule
      ),
  },
  {
    path: "my-smart-invest",
    loadChildren: () =>
      import("./pages/my-smart-investment/my-smart-investment.module").then(
        (m) => m.MySmartInvestmentModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "asset-detail/:assetId",
    loadChildren: () =>
      import("./pages/asset-detail/asset-detail.module").then(
        (m) => m.AssetDetailModule
      ),
  },
  {
    path: "testimonials",
    loadChildren: () =>
      import("./pages/testimonials/testimonials.module").then(
        (m) => m.TestimonialsModule
      ),
  },
  {
    path: "profile",
    loadChildren: () =>
      import("./pages/profile/profile.module").then((m) => m.ProfileModule),
    canActivate: [AuthGuard],
  },
  {
    path: "my-investment",
    loadChildren: () =>
      import("./pages/my-investment/my-investment.module").then(
        (m) => m.MyInvestmentModule
      ),
    canActivate: [AuthGuard],
  },
  { path: "**", redirectTo: "login" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
