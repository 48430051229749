<router-outlet *ngIf="!showSideBar"></router-outlet>
<mat-drawer-container autosize>
  <mat-drawer #drawer mode="over">
    <app-side-bar (navbar)="drawer.toggle()" [type]="'over'"></app-side-bar>
  </mat-drawer>
  <div>
    <div class="section" *ngIf="showSideBar">
      <app-top-bar (navbar)="drawer.toggle()"></app-top-bar>
      <div class="d-flex flex-row h-100">
        <app-side-bar class="static-sidenav" [type]="'side'"></app-side-bar>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</mat-drawer-container>
