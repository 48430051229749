import { Component, Inject, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ReportService } from "src/services/report.service";
import { IGenericResponse } from "src/models/genericModels";
import { CommonService } from "src/services/common.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-generate-report-modal",
  templateUrl: "./generate-report-modal.component.html",
  styleUrls: ["./generate-report-modal.component.scss"],
})
export class GenerateReportModalComponent implements OnInit {
  reportTypeList: Array<{ value: string; id: string }> = [
    { value: "Investment Report", id: "INVESTMENT_REPORT" },
    { value: "Income Report", id: "INCOME_REPORT" },
    { value: "Account Statement", id: "ACCOUNT_STATEMENT" },
  ];
  reportForm = new FormGroup(
    {
      type: new FormControl("", [Validators.required]),
      start_date: new FormControl("", [
        Validators.required,
        this.dateNotGreaterThanTodayValidator,
      ]),
      end_date: new FormControl("", [
        Validators.required,
        this.dateNotGreaterThanTodayValidator,
      ]),
    },
    { validators: this.dateRangeValidator }
  );

  constructor(
    public dialogRef: MatDialogRef<GenerateReportModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private reportService: ReportService,
    private commonService: CommonService,
    private datePipe: DatePipe
  ) {}

  public maxDate?: Date;
  today = new Date();

  ngOnInit(): void {
    this.maxDate = new Date();
  }

  generateReport(emailReports: boolean) {
    if (this.reportForm && this.reportForm.valid) {
      const startDate = new Date(
        String(this.reportForm.controls["start_date"].value)
      );
      const endDate = new Date(
        String(this.reportForm.controls["end_date"].value)
      );

      this.reportService
        .getReport(
          String(this.reportForm.controls["type"].value),
          this.formatDate(startDate),
          this.formatDate(endDate),
          emailReports
        )
        .subscribe({
          next: (res: IGenericResponse | Blob) => {
            if (res instanceof Blob) {
              if (
                res.type ==
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              ) {
                this.commonService.downloadBlobAsFile(res, "Report.xlsx");
                this.commonService.openSnackbar(
                  "File Downloaded Successfully !"
                );
              } else {
                this.commonService.blobToJson(res).then((result) => {
                  this.commonService.openSnackbar(result.message);
                });
              }
            } else {
              this.commonService.openSnackbar(res.message);
            }
            this.reportForm.reset();
            this.closeModal();
          },
          error: (err) => {
            console.error("Report Error:", err);
            this.commonService.openSnackbar(err.error.message);
          },
        });
    }
  }

  dateNotGreaterThanTodayValidator(formGroup: AbstractControl) {
    const start_date = formGroup.get("start_date")?.value;
    const end_date = formGroup.get("end_date")?.value;

    if (start_date && start_date > this.today) {
      formGroup.get("start_date")?.setErrors({ dateNotGreaterThanToday: true });
    } else if (end_date && end_date > this.today) {
      formGroup.get("end_date")?.setErrors({ dateNotGreaterThanToday: true });
    }
    return null;
  }

  dateRangeValidator(formGroup: AbstractControl) {
    const start_date = formGroup.get("start_date")?.value;
    const end_date = formGroup.get("end_date")?.value;

    if (start_date && end_date && start_date > end_date) {
      formGroup.get("end_date")?.setErrors({ dateRange: true });
    }
    return null;
  }

  formatDate(date: Date) {
    return date ? this.datePipe.transform(date, "yyyy-MM-dd") : null;
  }

  closeModal() {
    this.dialogRef.close();
  }
}
