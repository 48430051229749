<div
  class="asset-card"
  *ngIf="investment"
  (click)="navigateToAssetDetailPage(investment.asset)"
>
  <div class="d-flex flex-row">
    <span class="asset-img">
      <img
        *ngIf="investment.asset.image"
        [src]="investment.asset.image"
        [alt]="investment.asset.name"
      />
      <span class="img-text" *ngIf="!investment.asset.image">
        {{ investment.asset.name.charAt(0) }}
      </span>
    </span>
    <div class="d-flex flex-column">
      <span class="asset-title">{{ investment.asset.name }}</span>
      <div class="chips-container">
        <button *ngIf="investment.asset.isin" matRipple class="chips">
          ISIN- {{ investment.asset.isin }}
        </button>
        <button
          *ngIf="investment.asset.rating && investment.asset.rating_agency"
          matRipple
          class="chips"
        >
          {{ investment.asset.rating }} -
          {{ investment.asset.rating_agency }}
        </button>

        <button matRipple class="chips">
          {{ investment.asset.asset_class.name }}
        </button>
        <button matRipple class="chips">
          {{ investment.asset.is_secured ? "Secured" : "Unsecured" }}
        </button>
        <button matRipple class="chips">
          {{ investment.asset.is_listed ? "Listed" : "UnListed" }}
        </button>
      </div>
    </div>
  </div>
  <!-- <span class="isin-no">{{ asset.short_description }}</span> -->
  <div class="investment-status" [ngClass]="investment.status_type">
    Investment Status - Order {{ investment.status | titlecase }}
  </div>
  <span class="divider"></span>
  <div class="section-body">
    <div class="section-property-card">
      <div class="d-flex flex-row justify-content-between">
        <span class="heading">Yield to Maturity</span>
      </div>
      <span class="value">{{
        investment.asset.yield_to_maturity
          ? (investment.asset.yield_to_maturity | number: "1.0-2") + "%"
          : "NA"
      }}</span>
    </div>
    <div class="section-property-card">
      <div class="d-flex flex-row justify-content-between">
        <span class="heading">Amount Invested</span>
      </div>
      <span class="value">{{
        investment.amount ? "₹ " + (investment.amount | number: "1.2") : "NA"
      }}</span>
    </div>
    <div class="section-property-card">
      <div class="d-flex flex-row justify-content-between">
        <span class="heading">Units Booked</span>
      </div>
      <span class="value">{{ investment.units }}</span>
    </div>
    <div class="section-property-card">
      <div class="d-flex flex-row justify-content-between">
        <span class="heading">Principal Rep. Freq.</span>
      </div>
      <span class="value">{{
        investment.asset.principal_repayment_frequency
          ? investment.asset.principal_repayment_frequency
          : "NA"
      }}</span>
    </div>
    <div class="section-property-card">
      <div class="d-flex flex-row justify-content-between">
        <span class="heading">Interest Rep. Freq.</span>
      </div>
      <span class="value">{{
        investment.asset.interest_repayment_frequency
          ? investment.asset.interest_repayment_frequency
          : "NA"
      }}</span>
    </div>
    <div class="section-property-card">
      <div class="d-flex flex-row justify-content-between">
        <span class="heading">Maturity Date</span>
      </div>
      <span class="value">{{
        investment.maturity_date != null
          ? (investment.maturity_date | customDate)
          : "NA"
      }}</span>
    </div>
  </div>
  <button matRipple class="invest-now-btn">
    {{
      investment.asset.is_accepting_investment ? "Invest More" : "Check Details"
    }}
  </button>
</div>
<div
  (click)="navigateToAssetDetailPage(asset)"
  class="asset-card"
  *ngIf="asset"
>
  <div class="d-flex flex-row">
    <span class="asset-img">
      <img *ngIf="asset.image" [src]="asset.image" [alt]="asset.name" />
      <span class="img-text" *ngIf="!asset.image">
        {{ asset.name.charAt(0) }}
      </span>
    </span>
    <div class="d-flex flex-column">
      <span class="asset-title">{{ asset.name }}</span>
      <div class="chips-container">
        <button *ngIf="asset.isin" matRipple class="chips">
          ISIN- {{ asset.isin }}
        </button>
        <button
          *ngIf="asset.rating && asset.rating_agency"
          matRipple
          class="chips"
        >
          {{ asset.rating }} - {{ asset.rating_agency }}
        </button>

        <button matRipple class="chips">
          {{ asset.asset_class.name }}
        </button>
        <button matRipple class="chips">
          {{ asset.is_secured ? "Secured" : "Unsecured" }}
        </button>
        <button matRipple class="chips">
          {{ asset.is_listed ? "Listed" : "UnListed" }}
        </button>
      </div>
    </div>
  </div>
  <!-- <span class="isin-no">{{ asset.short_description }}</span> -->
  <span class="divider"></span>
  <div class="section-body">
    <div class="section-property-card">
      <div class="d-flex flex-row justify-content-between">
        <span class="heading">Yield to Maturity</span>
      </div>
      <span class="value">{{
        asset.yield_to_maturity
          ? (asset.yield_to_maturity | number: "1.0-2") + "%"
          : "NA"
      }}</span>
    </div>
    <div class="section-property-card">
      <div class="d-flex flex-row justify-content-between">
        <span class="heading">Coupon Rate</span>
      </div>
      <span class="value">{{
        asset.coupon_rate ? (asset.coupon_rate | number: "1.0-2") + "%" : "NA"
      }}</span>
    </div>
    <div class="section-property-card">
      <div class="d-flex flex-row justify-content-between">
        <span class="heading">Principal Rep. Freq.</span>
      </div>
      <span class="value">{{
        asset.principal_repayment_frequency
          ? asset.principal_repayment_frequency
          : "NA"
      }}</span>
    </div>
    <div class="section-property-card">
      <div class="d-flex flex-row justify-content-between">
        <span class="heading">Interest Rep. Freq.</span>
      </div>
      <span class="value">{{
        asset.interest_repayment_frequency
          ? asset.interest_repayment_frequency
          : "NA"
      }}</span>
    </div>
    <div class="section-property-card">
      <div class="d-flex flex-row justify-content-between">
        <span class="heading">Maturity Date</span>
      </div>
      <span class="value">{{
        asset.maturity_date != null ? (asset.maturity_date | customDate) : "NA"
      }}</span>
    </div>
    <div class="section-property-card">
      <div class="d-flex flex-row justify-content-between">
        <span class="heading">Minimum Investment</span>
      </div>
      <span class="value">
        {{
          asset.minimum_investment
            ? "₹ " + (asset.minimum_investment | number: "1.2")
            : "NA"
        }}</span
      >
    </div>
  </div>
  <div class="asset-progress-bar">
    <span class="progress-value">{{ asset.total_invested_amount_text }}</span>
  </div>
  <button matRipple class="invest-now-btn">
    {{ asset.is_accepting_investment ? "Invest Now" : "Check Details" }}
  </button>
</div>
