<div class="side-bar">
  <button
    class="sidebar-nav-btn"
    [routerLink]="['/home']"
    routerLinkActive="selected-tab"
    matRipple
    (click)="toggleNavbar()"
    matTooltip="Home"
    matTooltipPosition="after"
  >
    <span class="selected-indicator"></span>
    <div class="w-100">
      <img src="../../../../assets/icon/home.svg" alt="home-icon" />
    </div>
  </button>
  <button
    class="sidebar-nav-btn"
    [routerLink]="['/testimonials']"
    routerLinkActive="selected-tab"
    matRipple
    (click)="toggleNavbar()"
    matTooltip="Testimonials"
    matTooltipPosition="after"
  >
    <span class="selected-indicator"></span>
    <div class="w-100">
      <img src="../../../../assets/icon/chat.svg" alt="chat-icon" />
    </div>
  </button>
  <button
    class="sidebar-nav-btn"
    matRipple
    matTooltip="Feedback"
    matTooltipPosition="after"
    (click)="openFeedbackModal()"
  >
    <span class="selected-indicator"></span>
    <div class="w-100">
      <img src="../../../../assets/icon/feedback.svg" alt="feedback-icon" />
    </div>
  </button>
  <button
    class="sidebar-nav-btn"
    matRipple
    matTooltip="Reports"
    matTooltipPosition="after"
    (click)="openReportModal()"
  >
    <span class="selected-indicator"></span>
    <div class="w-100">
      <img
        src="../../../../assets/icon/chart-histogram-icon-black.svg"
        alt="report-icon"
        style="filter: contrast(5)"
      />
    </div>
  </button>
  <button
    class="sidebar-nav-btn"
    matRipple
    (click)="toggleNavbar()"
    routerLinkActive="selected-tab"
    [routerLink]="['/terms-of-service']"
    [queryParams]="
      userAuthService.accessToken
        ? { view: 'user-view' }
        : { view: 'auth-view' }
    "
    matTooltip="TnC & Policy"
    matTooltipPosition="after"
  >
    <span class="selected-indicator"></span>
    <div class="w-100">
      <img
        src="../../../../assets/icon/policy.svg"
        alt="policy-icon"
        class="unselected"
      />
      <img
        src="../../../../assets/icon/policy-selected.svg"
        alt="policy-icon"
        class="selected"
      />
    </div>
  </button>
</div>
