import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-investment-detail-modal",
  templateUrl: "./investment-detail-modal.component.html",
  styleUrls: ["./investment-detail-modal.component.scss"],
})
export class InvestmentDetailModalComponent {
  currentDate = new Date();
  timeRemaining: string = "";
  day: any;
  month: any;
  year: any;
  constructor(
    public dialogRef: MatDialogRef<InvestmentDetailModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.calculateDateDifference(this.currentDate, data.maturity_date);
  }

  calculateDateDifference(currentDate: Date, maturityDate: Date) {
    const maturityDateObj = new Date(maturityDate);
    const yearDiff = maturityDateObj.getFullYear() - currentDate.getFullYear();
    const monthDiff = maturityDateObj.getMonth() - currentDate.getMonth();
    const dayDiff = maturityDateObj.getDate() - currentDate.getDate();

    if (yearDiff >= 0) {
      this.year = yearDiff;
    }

    if (monthDiff >= 0) {
      this.month = monthDiff;
    } else {
      if (this.year > 0) {
        this.year = this.year - 1;
      }
      this.month = 12 + monthDiff;
    }

    if (dayDiff >= 0) {
      this.day = dayDiff;
    } else {
      if (this.month > 0) {
        this.month = this.month - 1;
      }
      this.day = 30 + dayDiff;
    }
    this.timeRemaining =
      (this.year > 0 ? this.year + "Y " : "") +
      (this.month > 0 ? this.month + "M " : "") +
      (this.day > 0 ? this.day + "D " : "");
  }

  closeModal() {
    this.dialogRef.close();
  }
}
