import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({
  name: "customDate",
})
export class CustomDatePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (!value) return value;

    const datePipe = new DatePipe("en-US");
    const day = datePipe.transform(value, "d");
    const monthYear = datePipe.transform(value, "MMM yyyy");

    const dayWithSuffix = this.addOrdinalSuffix(day!);

    return `${dayWithSuffix} ${monthYear}`;
  }

  private addOrdinalSuffix(day: string): string {
    const dayNum = parseInt(day, 10);
    if (isNaN(dayNum)) return day;

    let suffix = "th";
    if (dayNum === 1 || dayNum === 21 || dayNum === 31) {
      suffix = "st";
    } else if (dayNum === 2 || dayNum === 22) {
      suffix = "nd";
    } else if (dayNum === 3 || dayNum === 23) {
      suffix = "rd";
    }

    return `${dayNum}${suffix}`;
  }
}
