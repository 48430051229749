import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { HomeConstant } from "src/constants/global-constants";
import { UserAuthService } from "../../../services/user-auth.service";
import { UserDetailProfile } from "src/models/profile";
import { environment } from "src/environments/environment";
import { CommonService } from "src/services/common.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  mainTabs: Array<any> = [];
  selectedTab: any;
  warning_action_text: string | undefined = "";
  warning_message_text: string | undefined = "";
  show_warning: boolean = false;
  constructor(
    private router: Router,
    public userAuthService: UserAuthService,
    public commonService: CommonService
  ) {
    this.mainTabs = [...HomeConstant.MainTabs];
    if (!this.userAuthService.accessToken) {
      this.mainTabs = this.mainTabs.filter(
        (tabInfo) => tabInfo.title != "Dashboard"
      );
    }
    this.selectedTab = this.mainTabs[0];
    this.userAuthService.resetNavbar$.subscribe((value) => {
      if (value) {
        this.selectedTab = this.mainTabs[0];
      }
    });
  }

  ngOnInit(): void {
    if (this.userAuthService.accessToken) {
      this.fetchProfileDetails();
    }
    if (!this.mainTabs) {
      this.mainTabs = [...HomeConstant.MainTabs];
      this.mainTabs = this.mainTabs.filter(
        (tabInfo) => tabInfo.title != "Dashboard"
      );
    }

    this.mainTabs.forEach((tab) => {
      if (this.router.url.includes(tab.route)) {
        this.selectedTab = tab;
      }
    });
  }

  changeTab(tab: any) {
    this.selectedTab = tab;
  }

  fetchProfileDetails() {
    this.userAuthService.fetchProfileDetails().subscribe({
      next: (response: UserDetailProfile) => {
        this.show_warning = response.data.show_warning;
        this.warning_action_text = response.data.warning_action_text;
        this.warning_message_text = response.data.warning_message_text;
        console.log("User Profile:", response);
      },
      error: (err: any) => {
        console.error("User Profile Error:", err);
      },
    });
  }
}
