import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "./shared/modules/shared.module";
import { MaterialModule } from "./shared/modules/material.module";
import { HttpClientModule } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { provideAuth, getAuth } from "@angular/fire/auth";
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { environment } from "src/environments/environment";
import { WithdrawalRequestReceivedModalComponent } from "./shared/components/withdrawal-request-received-modal/withdrawal-request-received-modal.component";
import { PixelModule } from "ngx-pixel";

@NgModule({
  declarations: [AppComponent, WithdrawalRequestReceivedModalComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    PixelModule.forRoot({
      enabled: true,
      pixelId: environment.pixelId,
    }),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    SharedModule,
    MaterialModule,
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
