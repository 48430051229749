import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { featureFlagListResponse } from "src/models/featureFlag";
import { FeatureFlagService } from "src/services/feature-flag.service";
import { UserAuthService } from "src/services/user-auth.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  showSideBar: boolean = false;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private featureFlagService: FeatureFlagService,
    public userAuthService: UserAuthService
  ) {
    this.router.events.subscribe((event: any) => {
      if (event && event.url && event.url !== "/") {
        if (event.url.includes("login")) {
          this.showSideBar = false;
        } else if (event.url.includes("signup")) {
          this.showSideBar = false;
        } else if (event.url.includes("password-recovery")) {
          this.showSideBar = false;
        } else if (event.url.includes("create-new-password")) {
          this.showSideBar = false;
        } else if (event.url.includes("terms-of-service")) {
          this.activatedRoute.queryParams.subscribe((queryParams) => {
            let redirect = queryParams["redirect"];
            this.showSideBar = redirect != "true" ? true : false;
          });
        } else if (event.url.includes("registration-fee-page")) {
          this.activatedRoute.queryParams.subscribe((queryParams) => {
            let redirect = queryParams["redirect"];
            this.showSideBar = redirect != "true" ? true : false;
          });
        } else if (event.url.includes("bank-detail")) {
          this.showSideBar = false;
        } else if (event.url.includes("user-profile")) {
          this.showSideBar = false;
        } else if (event.url.includes("address-detail")) {
          this.showSideBar = false;
        } else {
          this.showSideBar = true;
        }
      }
    });
    this.getAllFeatureFlags();
  }

  getAllFeatureFlags() {
    this.featureFlagService.fetchAllFeatureFlags().subscribe({
      next: (res: featureFlagListResponse) => {
        this.featureFlagService.setFeatureFlags(res.data);
        console.log("Feature Flags:", res.data);
      },
      error: (err) => {
        console.error("Feature Flags Error:", err);
      },
    });
  }
}
