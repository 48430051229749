import { Component, Input } from "@angular/core";

@Component({
  selector: "app-my-investment-card",
  templateUrl: "./my-investment-card.component.html",
  styleUrls: ["./my-investment-card.component.scss"],
})
export class MyInvestmentCardComponent {
  @Input() investment: any;
  constructor() {}
}
