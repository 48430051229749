<div class="section-card">
  <div class="detail-row">
    <span class="key">Amount Invested</span>
    <span class="value">₹ {{ investment.amount | number: "1.1-2" }}</span>
  </div>
  <div class="detail-row">
    <span class="key">Expected Returns</span>
    <span class="value"
      >₹ {{ investment.expected_returns | number: "1.1-2" }}</span
    >
  </div>
  <div class="detail-row">
    <span class="key">Invested On</span>
    <span class="value"> {{ investment.created_on | date: "d MMM y" }}</span>
  </div>
  <div class="detail-row">
    <span class="key">Maturity Date</span>
    <span class="value"> {{ investment.maturity_date | date: "d MMM y" }}</span>
  </div>
</div>
