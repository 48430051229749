import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[alphanumericOnly]",
})
export class AlphanumericOnlyDirective {
  constructor(private el: ElementRef) {}

  @HostListener("input", ["$event"]) onInput(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    const pattern = /^[A-Z0-9]*$/; // Regular expression to allow capital letters and numbers only

    if (!pattern.test(value)) {
      input.value = value.replace(/[^A-Z0-9]/g, ""); // Remove characters that don't match the pattern
    }
  }

  @HostListener("blur", ["$event"]) onBlur(event: Event): void {
    const input = this.el.nativeElement as HTMLInputElement;
    input.value = input.value.toUpperCase(); // Convert the input to uppercase on blur
  }
}
