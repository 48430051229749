<div class="modal-container" *ngIf="!submitted">
  <div class="d-flex flex-row justify-content-between align-items-center">
    <span class="title">Share your Experience</span>
    <div class="close-btn" matRipple (click)="closeModal(false)">
      <img src="../../../../assets/icon/close_icon.svg" />
    </div>
  </div>
  <app-input-rating
    (rate)="changeRating($event)"
    [rating]="rating"
  ></app-input-rating>
  <textarea
    rows="6"
    placeholder="Please tell us more"
    [(ngModel)]="reviewText"
    required
  ></textarea>
  <button
    type="submit"
    class="submit-btn"
    matRipple
    (click)="submit()"
    [disabled]="isSubmitting || reviewText.length == 0 || rating == 0"
  >
    <span *ngIf="isSubmitting">
      <mat-spinner diameter="20" strokeWidth="2"></mat-spinner>
    </span>
    <span *ngIf="!isSubmitting">Submit</span>
  </button>
</div>
<div class="modal-container review-submitted" *ngIf="submitted">
  <img src="../../../../assets/image/review-submitted.svg" />
  <span class="submitted-title">Thank you for submitting your review.</span>
  <span class="submitted-description"
    >We appreciate your kind words and are grateful for your support.</span
  >
  <button
    type="button"
    matRipple
    class="secondary-btn"
    (click)="closeModal(true)"
  >
    OK
  </button>
</div>
