import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { IFeedbackCategoryListResponse } from "src/models/feedback";
import { IGenericResponse } from "src/models/genericModels";
@Injectable({
  providedIn: "root",
})
export class FeedbackService {
  constructor(private http: HttpClient) {}

  fetchFeedbackCategoryList(): Observable<IFeedbackCategoryListResponse> {
    const url = `${environment.baseUrl}apis/users/feedback/v1/categories/`;
    let httpOptions = {};
    return this.http.get<IFeedbackCategoryListResponse>(url, httpOptions);
  }

  provideFeedback(body: any): Observable<IGenericResponse> {
    const url = `${environment.baseUrl}apis/users/feedback/v1/`;
    let httpOptions = {};
    return this.http.post<IGenericResponse>(url, body, httpOptions);
  }
}
