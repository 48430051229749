<div class="modal-container">
  <img
    class="investment-successfull-image"
    src="../../../../assets/image/payment_success.png"
    alt="investment successfull"
  />
  <span class="title">Success</span>
  <span class="sub-title">Units Blocked Successfully!</span>
  <span class="description"
    >Your Relationship Manger will<br />
    contact you for further Steps.</span
  >

  <button class="close-btn" matRipple (click)="closeModal()">
    <img
      class="vector"
      src="../../../../assets/image/vector.svg"
      alt="close-icon"
    />
  </button>
</div>
