<form [formGroup]="feedbackForm" class="modal-container feedback">
  <div class="d-flex flex-row justify-content-between align-items-center">
    <span class="title">Share Feedback</span>
    <div class="close-btn" matRipple (click)="closeModal()">
      <img src="../../../../assets/icon/close_icon.svg" />
    </div>
  </div>
  <div class="form-input">
    <div class="input-box">
      <input
        matInput
        type="email"
        placeholder="Email"
        formControlName="email"
      />
    </div>
  </div>
  <span class="or-text">OR</span>
  <div class="form-input">
    <div class="input-box">
      <input
        matInput
        type="tel"
        placeholder="Phone Number"
        formControlName="phone_number"
      />
    </div>
  </div>
  <div class="form-input">
    <div class="input-box">
      <mat-select placeholder="Category" formControlName="category_id">
        <mat-option *ngFor="let category of categoryList" [value]="category.id">
          {{ category.category }}
        </mat-option>
      </mat-select>
    </div>
  </div>
  <div class="form-input">
    <div class="input-box">
      <input matInput type="text" placeholder="Title" formControlName="title" />
    </div>
  </div>
  <textarea
    rows="6"
    placeholder="Description"
    required
    formControlName="description"
  ></textarea>
  <button
    type="submit"
    class="submit-btn"
    matRipple
    (click)="submitFeedback()"
    [disabled]="
      feedbackForm.invalid ||
      loading ||
      (feedbackForm.controls['email'].value == '' &&
        feedbackForm.controls['phone_number'].value == '')
    "
  >
    Submit
  </button>
</form>
