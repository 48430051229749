<div class="modal-container h-100">
  <div class="d-flex flex-row justify-content-between align-items-center">
    <span class="title">Maturity Type</span>
    <div class="close-btn" matRipple (click)="closeModal()">
      <img src="../../../../assets/icon/close_icon.svg" alt="close-icon" />
    </div>
  </div>
  <div class="checkbox-container">
    <div *ngFor="let value of checkboxValues; let i = index">
      <mat-checkbox
        [color]="'primary'"
        [(ngModel)]="checkboxValues[i].status"
        >{{ checkboxValues[i].value }}</mat-checkbox
      >
    </div>
  </div>
  <div class="d-flex flex-column justify-content-between h-100">
    <div class="slider-container">
      <span class="slider-label">Investment Amount</span>
      <mat-slider class="slider" [min]="0" [max]="100" [step]="6">
        <input matSliderThumb [(ngModel)]="sliderValue1" />
      </mat-slider>
    </div>
    <div class="slider-container">
      <span class="slider-label">Investment Tenure</span>
      <mat-slider class="slider" [min]="0" [max]="100" [step]="6">
        <input matSliderThumb [(ngModel)]="sliderValue2" />
      </mat-slider>
    </div>
  </div>
  <div class="button-group">
    <button type="submit" class="clear-btn" (click)="clearFilter()" matRipple>
      Clear
    </button>
    <button type="submit" class="submit-btn" (click)="submitFilter()" matRipple>
      Apply
    </button>
  </div>
</div>
