<div class="modal-container">
  <img
    class="investment-failed-image"
    src="../../../../assets/image/payment-failed.png"
    alt="investment successfull"
  />
  <div class="title">Investment Failed</div>

  <button class="close-btn" matRipple (click)="closeModal()">
    <img
      class="vector"
      src="../../../../assets/image/vector.svg"
      alt="close-icon"
    />
  </button>
</div>
