import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
  AccountBalanceResponse,
  AddMoneyOrderStatus,
  AddMoneyResponse,
  WalletTransactionsResponse,
} from "../models/transaction";
import { IGenericResponse } from "src/models/genericModels";
import { VirtualBankAccountDetails } from "src/models/bankname";

@Injectable({
  providedIn: "root",
})
export class TransactionService {
  constructor(private http: HttpClient) {}

  public refreshBalance: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  refreshBalance$ = this.refreshBalance.asObservable();

  fetchBalance(): Observable<AccountBalanceResponse> {
    const token = `Token ${localStorage.getItem("authToken")}`;
    const url = `${environment.baseUrl}apis/accounting/accounts/v1/balance/`;
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: token }),
    };
    return this.http.get<AccountBalanceResponse>(url, httpOptions);
  }

  fetchTransaction(
    limit: number,
    offset: number
  ): Observable<WalletTransactionsResponse> {
    const token = `Token ${localStorage.getItem("authToken")}`;
    const url = `${environment.baseUrl}apis/accounting/wallet/v2/transactions/?limit=${limit}&offset=${offset}`;
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: token }),
    };
    return this.http.get<WalletTransactionsResponse>(url, httpOptions);
  }

  WithdrawMoney(body: any): Observable<IGenericResponse> {
    const token = `Token ${localStorage.getItem("authToken")}`;
    const url = `${environment.baseUrl}apis/accounting/wallet/v1/withdraw-money/`;
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: token }),
    };
    return this.http.post<IGenericResponse>(url, body, httpOptions);
  }

  addMoney(body: any): Observable<AddMoneyResponse> {
    const token = `Token ${localStorage.getItem("authToken")}`;
    const url = `${environment.baseUrl}apis/accounting/wallet/v1/add-money/order/`;
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: token }),
    };
    return this.http.post<AddMoneyResponse>(url, body, httpOptions);
  }

  checkAddMoneyOrderStatus(body: any): Observable<AddMoneyOrderStatus> {
    const token = `Token ${localStorage.getItem("authToken")}`;
    const url = `${environment.baseUrl}apis/accounting/wallet/v2/add-money/status/`;
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: token }),
    };
    return this.http.post<AddMoneyOrderStatus>(url, body, httpOptions);
  }

  fetchVirtualBankDetails(): Observable<VirtualBankAccountDetails> {
    const token = `Token ${localStorage.getItem("authToken")}`;
    const url = `${environment.baseUrl}apis/accounting/accounts/v1/virtual-account/`;
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: token }),
    };
    return this.http.get<VirtualBankAccountDetails>(url, httpOptions);
  }
}
