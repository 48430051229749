import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Category, IFeedbackCategoryListResponse } from "src/models/feedback";
import { IGenericResponse } from "src/models/genericModels";
import { CommonService } from "src/services/common.service";
import { FeedbackService } from "src/services/feedback.service";
import { UserAuthService } from "src/services/user-auth.service";

@Component({
  selector: "app-share-feedback-modal",
  templateUrl: "./share-feedback-modal.component.html",
  styleUrls: ["./share-feedback-modal.component.scss"],
})
export class ShareFeedbackModalComponent {
  categoryList: Array<Category> = [];
  user: any;
  loading: boolean = false;

  feedbackForm = new FormGroup({
    email: new FormControl("", [Validators.email]),
    phone_number: new FormControl("", [
      Validators.minLength(10),
      Validators.maxLength(15),
    ]),
    title: new FormControl("", [Validators.required]),
    description: new FormControl("", [Validators.required]),
    category_id: new FormControl<number | null>(null, [Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<ShareFeedbackModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private feedbackService: FeedbackService,
    private userAuthService: UserAuthService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.user = this.userAuthService.user;
    this.feedbackForm.controls["email"].setValue(this.user.email ?? "");
    this.feedbackForm.controls["phone_number"].setValue(
      this.user.mobile_number ?? ""
    );
    if (this.user.email) {
      this.feedbackForm.controls["email"].disable();
    }
    if (this.user.mobile_number) {
      this.feedbackForm.controls["phone_number"].disable();
    }
    this.getCategoryList();
  }

  closeModal() {
    this.dialogRef.close();
  }

  getCategoryList() {
    this.feedbackService.fetchFeedbackCategoryList().subscribe({
      next: (res: IFeedbackCategoryListResponse) => {
        this.categoryList = res.data;
        console.log("Feedback Category List:", res);
      },
      error: (err) => {
        console.error("Feedback Category List Error:", err);
      },
    });
  }

  submitFeedback() {
    if (this.feedbackForm.valid) {
      this.loading = true;
      this.feedbackForm.controls["email"].enable();
      this.feedbackForm.controls["phone_number"].enable();

      let body = {
        ...this.feedbackForm.value,
        user_id: this.user.id ?? "",
      };

      this.feedbackService.provideFeedback(body).subscribe({
        next: (res: IGenericResponse) => {
          console.log("Add Feedback:", res);
          this.feedbackForm.reset();
          this.loading = false;
          this.commonService.openSnackbar(res.message);
          this.closeModal();
        },
        error: (err: any) => {
          this.loading = false;
          console.error("Add Feedback Error:", err);
          this.commonService.openSnackbar(err.error.message);
        },
      });
    }
  }
}
