<div class="modal-container report">
  <div class="d-flex flex-row justify-content-between align-items-center">
    <span class="title">Reports</span>
    <div class="close-btn" matRipple (click)="closeModal()">
      <img src="../../../../assets/icon/close_icon.svg" />
    </div>
  </div>
  <div class="stat-container">
    <span>Average ROI : 12%</span>
    <span>Registered Investors: 1T+</span>
  </div>
  <form *ngIf="reportForm" [formGroup]="reportForm">
    <div class="form-input">
      <span class="form-label">Report Type</span>
      <div class="input-box">
        <mat-select formControlName="type" placeholder="Report Type">
          <mat-option *ngFor="let report of reportTypeList" [value]="report.id">
            {{ report.value }}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div class="form-input">
      <span class="form-label">Start Date</span>
      <div class="input-box">
        <input
          matInput
          [max]="
            reportForm.get('end_date')?.value
              ? reportForm.get('end_date')?.value
              : maxDate
          "
          formControlName="start_date"
          [matDatepicker]="picker1"
          placeholder="Start Date"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </div>
    </div>
    <div class="form-input">
      <span class="form-label">End Date</span>
      <div class="input-box">
        <input
          matInput
          [min]="reportForm.get('start_date')?.value"
          [max]="maxDate"
          formControlName="end_date"
          [matDatepicker]="picker2"
          placeholder="End Date"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </div>
    </div>
    <button
      class="email-report-btn"
      [disabled]="!this.reportForm.valid"
      matRipple
      (click)="generateReport(true)"
    >
      Email Report
    </button>
    <button
      class="download-report-btn"
      [disabled]="!this.reportForm.valid"
      matRipple
      (click)="generateReport(false)"
    >
      Download Report
    </button>
  </form>
</div>
