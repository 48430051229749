<div class="modal-container h-100">
  <div class="d-flex flex-row justify-content-between align-items-center">
    <span class="title">Join Us to Invest</span>
    <div class="close-btn" matRipple (click)="closeModal()">
      <img src="../../../../assets/icon/close_icon.svg" alt="close-icon" />
    </div>
  </div>
  <div class="d-flex flex-column justify-content-between h-100">
    <div class="image-container">
      <img
        class="join-us-to-invest-image"
        src="../../../../assets/image/join_us_to_invest.svg"
        alt="join us to invest"
      />
    </div>
    <button
      type="submit"
      class="submit-btn"
      (click)="redirectToLogin()"
      matRipple
    >
      Get Started
    </button>
  </div>
</div>
