import { Component, Inject } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { TransactionService } from "../../../../services/transaction.service";
import {
  AddMoneyResponse,
  Transaction,
  WalletTransactionsResponse,
} from "../../../../models/transaction";
import { DatePipe } from "@angular/common";
import { IGenericResponse } from "src/models/genericModels";
import { CommonService } from "src/services/common.service";
import { Router } from "@angular/router";
import { UserAuthService } from "src/services/user-auth.service";
import { UserDetailProfile } from "src/models/profile";
import { ShowWarningModalComponent } from "../show-warning-modal/show-warning-modal.component";
import {
  VirtualBankAccount,
  VirtualBankAccountDetails,
} from "src/models/bankname";

@Component({
  selector: "app-account-balance-modal",
  templateUrl: "./account-balance-modal.component.html",
  styleUrls: ["./account-balance-modal.component.scss"],
})
export class AccountBalanceModalComponent {
  showBankDetailforNEFT: boolean = false;
  withdrawAmount: number = 0;
  addAmount: number = 100;
  transactions: Array<Transaction> = [];
  gotAllTransactions: boolean = false;
  transactionLimit: number = 10;
  loading: boolean = false;
  transactionLoading: boolean = false;
  show_warning: boolean = false;
  targetRoute: string = "";
  virtualAccountDetails!: VirtualBankAccount;
  constructor(
    public dialogRef: MatDialogRef<AccountBalanceModalComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private transactionService: TransactionService,
    public datepipe: DatePipe,
    public commonService: CommonService,
    private router: Router,
    public userAuthService: UserAuthService
  ) {
    this.fetchProfileDetails();
    this.getTransaction();
  }

  fetchProfileDetails() {
    this.userAuthService.fetchProfileDetails().subscribe({
      next: (response: UserDetailProfile) => {
        if (response.data.show_warning) {
          this.show_warning = response.data.show_warning;
          this.targetRoute = response.data.navigate_to;
        }
        console.log("User Profile:", response);
      },
      error: (err: any) => {
        console.error("User Profile Error:", err);
      },
    });
  }

  fetchVirtualBankAccountDetails() {
    this.transactionService.fetchVirtualBankDetails().subscribe({
      next: (res: VirtualBankAccountDetails) => {
        console.log("Virtual Account Details Error", res);
        this.virtualAccountDetails = res.data;
      },
      error: (err: any) => {
        console.error("Virtual Account Details Error", err);
      },
    });
  }

  getTransaction() {
    if (!this.gotAllTransactions) {
      this.transactionLoading = true;
      this.transactionService
        .fetchTransaction(this.transactionLimit, this.transactions.length)
        .subscribe({
          next: (res: WalletTransactionsResponse) => {
            this.gotAllTransactions = res.data.length === 0;
            this.transactionLoading = false;
            this.transactions = this.transactions.concat(res.data);
            console.log("Transaction History:", this.transactions);
          },
          error: (err) => {
            this.transactionLoading = false;
            console.error("Transaction History Error:", err);
          },
        });
    }
  }

  getMonthSeparation(index: number) {
    let currentTransactionDate = new Date(
      this.transactions[index].transaction_date
    );
    let currentTransformedDate =
      this.datepipe.transform(currentTransactionDate, "MMMM yyyy") ?? "";
    if (index == 0) {
      return currentTransformedDate;
    } else {
      let previousTransactionDate = new Date(
        this.transactions[index - 1].transaction_date
      );
      let previousTransformedDate =
        this.datepipe.transform(previousTransactionDate, "MMMM yyyy") ?? "";
      return currentTransformedDate !== previousTransformedDate
        ? currentTransformedDate
        : "";
    }
  }

  withdrawMoney() {
    if (this.withdrawAmount > 0) {
      this.loading = true;
      this.transactionService
        .WithdrawMoney({ amount: this.withdrawAmount })
        .subscribe({
          next: (response: IGenericResponse) => {
            this.withdrawAmount = 0;
            this.loading = false;
            this.closeModal({ refreshBalance: true });
            //Todo: Open Withdraw Success Modal
            this.commonService.openSnackbar(response.message);
            console.log("Withdraw Money:", response);
          },
          error: (err: any) => {
            this.loading = false;
            this.commonService.openSnackbar(err.error.message);
            //Todo: Open Withdraw Failed Modal
            console.error("Withdraw Money Error:", err);
          },
        });
    } else {
      this.commonService.openSnackbar("Please enter valid amount !");
    }
  }

  addMoney() {
    if (this.show_warning) {
      this.closeModal();
      this.dialog.open(ShowWarningModalComponent, {
        data: { targetRoute: this.targetRoute },
        width: "426px",
        height: "auto",
        panelClass: ["show-warning-modal"],
      });
    } else if (this.addAmount > 0) {
      this.loading = true;
      this.transactionService
        .addMoney({
          amount: this.addAmount,
          redirection_url: "https://test.com/check-payment-status",
        })
        .subscribe({
          next: (response: AddMoneyResponse) => {
            localStorage.setItem("order_id", response.data.order_id);
            // Navigate to the payment gateway URL
            window.open(response.data.pg_url, "_self");
          },
          error: (err: any) => {
            this.loading = false;
            this.commonService.openSnackbar(err.error.message);
            console.error("Add Money Error:", err);
          },
        });
    } else {
      this.commonService.openSnackbar("Please enter valid amount !");
    }
  }

  closeModal(data: any = {}) {
    this.dialogRef.close(data);
  }
}
