<div class="left-section on-boarding-slider">
  <div class="bubble-bg">
    <div id="leftPanelCarousel" class="carousel slide">
      <div class="carousel-indicators">
        <button
          type="button"
          data-bs-target="#leftPanelCarousel"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
        ></button>
        <button
          type="button"
          data-bs-target="#leftPanelCarousel"
          data-bs-slide-to="1"
        ></button>
        <button
          type="button"
          data-bs-target="#leftPanelCarousel"
          data-bs-slide-to="2"
        ></button>
      </div>

      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="d-flex flex-column align-items-center">
            <img
              src="../../../assets/image/smart_invest.svg"
              class="main-image"
            />
            <span class="image-title">Smart Invest</span>
            <span class="image-sub-text"
              >Each high returns in short tenure with risk free
              investments</span
            >
          </div>
        </div>
        <div class="carousel-item">
          <div class="d-flex flex-column align-items-center">
            <img
              src="../../../assets/image/fixed_deposit.svg"
              class="main-image"
            />
            <span class="image-title">Fixed Deposits</span>
            <span class="image-sub-text"
              >Invest in a secured investment instrument for a fixed period and
              let us take care of the heavy lifting</span
            >
          </div>
        </div>
        <div class="carousel-item">
          <div class="d-flex flex-column align-items-center">
            <img src="../../../assets/image/sip.svg" class="main-image" />
            <span class="image-title">SIP</span>
            <span class="image-sub-text"
              >For investors who like to go slow but cconsistent, This is a one
              stop solution to make big returns</span
            >
          </div>
        </div>
      </div>
    </div>
    <span class="skip-text" routerLink="/home/smart-invest">Skip</span>
  </div>
</div>
