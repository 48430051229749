import { Component, Inject } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AccountBalanceModalComponent } from "../account-balance-modal/account-balance-modal.component";
import { AccountBalanceResponse } from "src/models/transaction";
import { TransactionService } from "src/services/transaction.service";

@Component({
  selector: "app-show-warning-modal",
  templateUrl: "./show-warning-modal.component.html",
  styleUrls: ["./show-warning-modal.component.scss"],
})
export class ShowWarningModalComponent {
  warningMessage: string = "";
  content?: string;
  assetPath: string = "../../assets/image/complete_profile_brand_colors.svg";
  button_text: string = "";
  balance: number = 0;
  constructor(
    public dialogRef: MatDialogRef<ShowWarningModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private route: ActivatedRoute,
    public transactionService: TransactionService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.warningMessage = this.getDisplayMessage();
  }

  getAccountBalance() {
    this.transactionService.fetchBalance().subscribe({
      next: (res: AccountBalanceResponse) => {
        this.balance = Number(res.data.balance);
        console.log("Account Balance:", this.balance);
      },
      error: (err) => {
        console.error("Error while fetching balance:", err);
      },
    });
  }

  getDisplayMessage() {
    if (this.data.targetRoute == "EditProfile") {
      this.button_text = "Complete Profile";
      this.assetPath = "../../assets/image/complete_profile_brand_colors.svg";
      return "Oh no! It looks like your profile information is incomplete. Don't worry, it's an easy fix. Please provide the necessary information to proceed.";
    } else if (
      this.data.targetRoute == "PayRegistrationFee" ||
      this.data.targetRoute == "RegistrationFeeEarlyAccessDiscount"
    ) {
      this.button_text = "Pay Registration Fee";
      this.assetPath =
        "../../assets/image/pay_registration_fee_brand_colors.svg";
      return "Oops! It looks like the registration fee hasn't been paid yet. Let's get that taken care of, please submit payment to continue.";
    } else if (this.data.targetRoute == "TermsOfService") {
      this.button_text = "Accept Terms Of Service";
      this.assetPath = "../../assets/image/complete_profile_brand_colors.svg";
      return "Whoopsie! It looks like you haven't accepted the terms and conditions yet. No worries though, it's an easy step to complete. Please accept the terms and conditions to move forward. Thank you!";
    } else if (this.data.targetRoute == "EditBankAccount") {
      this.button_text = "Add Bank Details";
      this.assetPath = "../../assets/image/complete_profile_brand_colors.svg";
      return "Uh oh! It looks like your bank details haven't been submitted yet. Let's fix that right away! Please submit your bank details to move forward. Thank you!";
    } else if (this.data.targetRoute == "Wallet") {
      this.button_text = "Add Money";
      this.assetPath =
        "../../assets/image/add_funds_to_wallet_brand_colors.svg";
      return "Uh oh! It looks like your balance is low. Add money to the wallet to keep Investing and earning high returns!";
    } else if (this.data.targetRoute == "EditAddress") {
      this.button_text = "Add Address";
      this.assetPath = "../../assets/image/add_address_brand_colors.svg";
      return "Oh no! It looks like your address information is missing. Don't worry, it's an easy fix. Please provide the necessary information to proceed.";
    } else if (this.data.targetRoute == "Raise Support Request") {
      this.button_text = "Raise a Support Request";
      this.assetPath = "../../assets/image/upload_docuent.svg";
      this.content =
        "To protect you from identity theft changing documents is not allowed.";
      return "If you wish to reupload the document please raise a support request or email us at info@icreditspace.com";
    } else {
      this.button_text = "Complete Profile";
      this.assetPath = "../../assets/image/complete_profile_brand_colors.svg";
      return "Oh no! It looks like your profile information is incomplete. Don't worry, it's an easy fix. Please provide the necessary information to proceed.";
    }
  }

  completeProfile() {
    if (this.data.targetRoute == "PayRegistrationFee") {
      this.router.navigate(["/registration-fee-page"]);
    } else if (this.data.targetRoute == "RegistrationFeeEarlyAccessDiscount") {
      this.router.navigate(["/registration-fee-page"], {
        queryParams: { view: "auth-view" },
      });
    } else if (this.data.targetRoute == "EditProfile") {
      this.router.navigate(["/user-profile"]);
    } else if (this.data.targetRoute == "TermsOfService") {
      this.router.navigate(["/terms-of-service"]);
    } else if (this.data.targetRoute == "EditBankAccount") {
      this.router.navigate(["/bank-detail"]);
    } else if (this.data.targetRoute == "EditAddress") {
      this.router.navigate(["/profile"]);
    } else if (this.data.targetRoute == "Raise Support Request") {
      this.redirectToSupportRequest();
    } else if (this.data.targetRoute == "EditDocuments") {
      this.router.navigate(["/profile"]);
    } else if (this.data.targetRoute == "Wallet") {
      this.openAccountBalanceModal();
    } else if (this.data.targetRoute == "EditDematAccount") {
      this.router.navigate(["/profile"]);
    } else {
      this.router.navigate(["/home"]);
    }
  }

  openAccountBalanceModal() {
    const dialogRef = this.dialog.open(AccountBalanceModalComponent, {
      data: { balance: this.balance, isModalView: true, selectedIndex: 0 },
      width: "542px",
      panelClass: ["account-balance-modal"],
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res.refreshBalance) {
        this.getAccountBalance();
      }
    });
  }

  redirectToSupportRequest() {
    // Get the current query parameters
    const currentQueryParams = this.route.snapshot.queryParams;

    // Create a new object with the desired changes
    const updatedQueryParams = {
      ...currentQueryParams,
      tab: "SUPPORT_REQUEST",
    };

    // Navigate to the same route with the updated query parameters
    this.router.navigate(["/profile"], { queryParams: updatedQueryParams });
  }
  closeModal() {
    this.dialogRef.close();
  }
}
