import { IGenericResponse } from "./genericModels";

export enum TransactionStatus {
  DEBITED = "DEBITED",
  CREDITED = "CREDITED",
}

export interface Transaction {
  amount: number;
  transaction_id: string;
  purpose: string;
  status: string;
  transaction_date: string;
}

export interface WalletTransactionsResponse extends IGenericResponse {
  data: Array<Transaction>;
}

export interface AccountBalanceResponse extends IGenericResponse {
  data: {
    user_id: number;
    balance: string;
  };
}

export interface AddMoneyResponse extends IGenericResponse {
  data: {
    pg_url: string;
    order_id: string;
    payment_gateway: string;
  };
}

export interface AddMoneyOrderStatus extends IGenericResponse {
  data: {
    status: string;
  };
}
