import { Component, Inject } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { IGenericResponse } from "src/models/genericModels";
import { CommonService } from "src/services/common.service";
import { SmartSaverService } from "src/services/smart-saver.service";
import { UserAuthService } from "src/services/user-auth.service";
import { WithdrawalRequestReceivedModalComponent } from "../withdrawal-request-received-modal/withdrawal-request-received-modal.component";
import { FeatureFlagService } from "src/services/feature-flag.service";
import { featureFlag, featureFlagResponse } from "src/models/featureFlag";

@Component({
  selector: "app-withdraw-investment-modal",
  templateUrl: "./withdraw-investment-modal.component.html",
  styleUrls: ["./withdraw-investment-modal.component.scss"],
})
export class WithdrawInvestmentModalComponent {
  amount: number = 500;
  isAmountValid: boolean = true;
  request_withdraw_clicked: boolean = false;
  loading: boolean = false;
  showWithdrawOption: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<WithdrawInvestmentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService,
    public userAuthService: UserAuthService,
    private smartSaverService: SmartSaverService,
    private dialog: MatDialog,
    private featureFlagService: FeatureFlagService
  ) {
    this.amount = data.amount;
    const flagName: string = "SS_WITHDRAWAL_TO_WALLET";
    const flags: featureFlag[] = this.featureFlagService.getFeatureFlags();
    const flagIndex = flags.findIndex((item) => item.name == flagName);
    if (flagIndex < 0) {
      this.featureFlagService.fetchFeatureFlag(flagName).subscribe({
        next: (res: featureFlagResponse) => {
          this.showWithdrawOption = res.data.is_active;
          console.log("Feature Flag", res.data);
        },
        error: (err) => {
          console.error("Feature Flag Error", err);
        },
      });
    } else {
      this.showWithdrawOption = flags[flagIndex].is_active;
    }
  }

  onInputChange() {
    if (this.data.available_to_withdraw > 100) {
      const maxAmount =
        this.data.available_to_withdraw > 300000
          ? 300000
          : this.data.available_to_withdraw;

      this.isAmountValid =
        !isNaN(this.amount) && this.amount >= 100 && this.amount <= maxAmount;
    }
  }

  withdrawInvestment(add_funds_to_wallet: boolean) {
    if (this.userAuthService.accessToken && !this.loading) {
      this.loading = true;
      this.smartSaverService
        .withdrawMoney({
          amount: this.amount,
          add_funds_to_wallet: add_funds_to_wallet,
        })
        .subscribe({
          next: (response: IGenericResponse) => {
            this.amount = 0;
            this.loading = false;
            this.closeModal(true);
            this.dialog.open(WithdrawalRequestReceivedModalComponent, {
              width: "400px",
              panelClass: ["withdrawal-request-received-modal"],
            });
            console.log("Smart Saver Withdraw Money :", response);
          },
          error: (err: any) => {
            this.loading = false;
            this.commonService.openSnackbar(err.error.message);
            console.error("Smart Saver Withdraw Money Error:", err);
          },
        });
    }
  }

  closeModal(refresh: boolean = false) {
    this.dialogRef.close({ refresh: refresh });
  }

  requestWithdraw() {
    if (this.showWithdrawOption) {
      this.request_withdraw_clicked = true;
    } else {
      this.withdrawInvestment(false);
    }
  }
}
