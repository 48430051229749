import { Directive, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
  selector: "[appDematAccount]",
})
export class DematAccountDirective {
  constructor(private ngControl: NgControl) {}

  @HostListener("input", ["$event.target.value"])
  onInput(value: string) {
    // Convert the input value to capital case
    const transformedValue = this.toCapitalCase(value);
    // Set the transformed value back to the input control
    this.ngControl.control?.setValue(transformedValue);
  }

  // Function to convert string to capital case
  private toCapitalCase(value: string): string {
    if (!value) return value;
    return value
      .split(" ")
      .map((word) => this.capitalizeAllLetters(word))
      .join(" ");
  }

  // Function to capitalize all letters of a word
  private capitalizeAllLetters(word: string): string {
    return word.toUpperCase();
  }
}
