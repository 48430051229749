import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { UserAuthService } from "src/services/user-auth.service";
import { AccountBalanceModalComponent } from "../account-balance-modal/account-balance-modal.component";
import { TransactionService } from "src/services/transaction.service";
import { AccountBalanceResponse } from "../../../../models/transaction";

@Component({
  selector: "app-top-bar",
  templateUrl: "./top-bar.component.html",
  styleUrls: ["./top-bar.component.scss"],
})
export class TopBarComponent implements OnInit {
  balance: number = 0;
  title: string = "Home";

  @Output() navbar = new EventEmitter();

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public userAuthService: UserAuthService,
    public transactionService: TransactionService
  ) {
    this.router.events.subscribe(() => {
      if (this.router.url.includes("home")) {
        this.title = "Home";
      } else if (this.router.url.includes("testimonials")) {
        this.title = "Testimonials";
      } else if (this.router.url.includes("asset-list")) {
        this.title = "Asset Listing";
      } else if (this.router.url.includes("my-smart-invest")) {
        this.title = "My Investments";
      } else if (this.router.url.includes("terms-of-service")) {
        this.title = "TnC & Policy";
      } else if (this.router.url.includes("return-calculator")) {
        this.title = "Return Calculator";
      } else if (this.router.url.includes("my-investment")) {
        if (this.router.url.includes("sip-investment")) {
          this.title = "My SIPs";
        } else if (this.router.url.includes("fd-investment")) {
          this.title = "Finzace - My Smart Deposits";
        }
      }
    });
    this.transactionService.refreshBalance$.subscribe((value) => {
      if (value) {
        this.getAccountBalance();
      }
    });
  }

  ngOnInit(): void {
    if (this.userAuthService.accessToken) {
      this.getAccountBalance();
    }
  }

  toggleNavbar() {
    this.navbar.emit();
  }

  logout() {
    this.userAuthService.logout();
  }

  getAccountBalance() {
    this.transactionService.fetchBalance().subscribe({
      next: (res: AccountBalanceResponse) => {
        this.balance = Number(res.data.balance);
        console.log("Account Balance:", this.balance);
      },
      error: (err) => {
        console.error("Error while fetching balance:", err);
      },
    });
  }

  openAccountBalanceModal() {
    const dialogRef = this.dialog.open(AccountBalanceModalComponent, {
      data: { balance: this.balance, isModalView: true, selectedIndex: 0 },
      width: "542px",
      panelClass: ["account-balance-modal"],
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res.refreshBalance) {
        this.getAccountBalance();
      }
    });
  }
}
