import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-investment-failed-modal",
  templateUrl: "./investment-failed-modal.component.html",
  styleUrls: ["./investment-failed-modal.component.scss"],
})
export class InvestmentFailedModalComponent {
  constructor(public dialogRef: MatDialogRef<InvestmentFailedModalComponent>) {}

  ngOnInit() {}

  closeModal() {
    this.dialogRef.close();
  }
}
