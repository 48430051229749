import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
  featureFlag,
  featureFlagListResponse,
  featureFlagResponse,
} from "src/models/featureFlag";

@Injectable({
  providedIn: "root",
})
export class FeatureFlagService {
  constructor(private http: HttpClient) {}

  getFeatureFlags(): featureFlag[] {
    const flags = sessionStorage.getItem("feature-flags");
    return flags == null ? [] : JSON.parse(flags);
  }

  setFeatureFlags(featureFlags: featureFlag[]) {
    sessionStorage.setItem("feature-flags", JSON.stringify(featureFlags));
  }

  fetchAllFeatureFlags(): Observable<featureFlagListResponse> {
    const token = `Token ${localStorage.getItem("authToken")}`;
    const url = `${environment.baseUrl}apis/rule-engine/v1/feature-flag/all/?platform=WEB&version=`;
    return this.http.get<featureFlagListResponse>(url, {});
  }

  fetchFeatureFlag(flagName: string): Observable<featureFlagResponse> {
    const token = `Token ${localStorage.getItem("authToken")}`;
    const url = `${environment.baseUrl}apis/rule-engine/v1/feature-flag/?feature_name=${flagName}&platform=WEB&version=`;
    return this.http.get<featureFlagResponse>(url, {});
  }
}
