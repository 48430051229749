<div class="modal-container account-balance">
  <div class="d-flex flex-row justify-content-between align-items-center">
    <span class="title"
      ><span class="key">Current Balance</span> : ₹{{
        data.balance | number: "1.0-2"
      }}
    </span>
    <div class="close-btn" matRipple (click)="closeModal()">
      <img src="../../../../assets/icon/close_icon.svg" />
    </div>
  </div>
  <mat-tab-group animationDuration="0ms" class="h-100">
    <mat-tab label="Add Money" [aria-label]="'ADD_MONEY'">
      <div class="tab-section">
        <div *ngIf="!showBankDetailforNEFT" class="content-section">
          <div class="d-flex flex-column">
            <div class="form-input">
              <div class="input-box">
                <input
                  matInput
                  type="number"
                  placeholder="Enter Amount"
                  [(ngModel)]="addAmount"
                />
              </div>
            </div>
            <div class="amount-btn-row">
              <button
                type="submit"
                class="amount-btn"
                matRipple
                (click)="addAmount = 10000"
              >
                ₹10,000
              </button>
              <button
                type="submit"
                class="amount-btn"
                matRipple
                (click)="addAmount = 50000"
              >
                ₹50,000
              </button>
              <button
                type="submit"
                class="amount-btn"
                matRipple
                (click)="addAmount = 100000"
              >
                ₹1 Lakh
              </button>
            </div>
            <span class="or">OR</span>
            <span class="title text-center"
              ><span
                (click)="
                  showBankDetailforNEFT = !showBankDetailforNEFT;
                  fetchVirtualBankAccountDetails()
                "
                class="key cursor-pointer"
                >Add Money via Neft / RTGS / IMPS</span
              ></span
            >
          </div>
          <button
            type="submit"
            class="add-or-withdraw-money-btn"
            matRipple
            (click)="addMoney()"
            [disabled]="addAmount < 1 || addAmount > 5000000 || loading"
          >
            Add Money
          </button>
        </div>
        <div
          *ngIf="showBankDetailforNEFT && virtualAccountDetails"
          class="content-section"
        >
          <div>
            <div class="account-detail-header">
              <img
                src="../../../assets/icon/arrow_left.svg"
                height="24"
                width="24"
                alt="arrow-back"
                class="arrow-back cursor-pointer"
                (click)="showBankDetailforNEFT = !showBankDetailforNEFT"
              />
              <span class="title text-center"
                ><span class="key">Add Money via Neft / RTGS / IMPS</span></span
              >
            </div>
            <div class="account-detail-container">
              <div class="image-container">
                <span class="account-detail-icon">
                  <img
                    src="../../../assets/icon/bank-icon-black.svg"
                    height="18"
                    width="18"
                    alt="arrow-back"
                    class="arrow-back cursor-pointer"
                  />
                </span>
                <span class="vertical-line"></span>
                <span class="account-detail-icon">
                  <img
                    src="../../../assets/icon/telegram.svg"
                    height="22"
                    width="22"
                    alt="arrow-back"
                    class="arrow-back cursor-pointer"
                  />
                </span>
              </div>
              <div class="d-flex flex-column">
                <span class="container-heading"
                  >Transfer money only to the following account</span
                >
                <div class="beneficiary-account-detail">
                  <div class="d-flex flex-column gap-1">
                    <span class="text">Bank Name:</span>
                    <span class="text">Account Number:</span>
                    <span class="text">Account Type:</span>
                    <span class="text">IFSC Code:</span>
                    <span class="text">Beneficiary Name:</span>
                  </div>
                  <div class="d-flex flex-column gap-1">
                    <span class="text">{{
                      virtualAccountDetails.bank_name
                    }}</span>
                    <span class="text">{{
                      virtualAccountDetails.account_number
                    }}</span>
                    <span class="text">{{
                      virtualAccountDetails.account_type
                    }}</span>
                    <span class="text">{{ virtualAccountDetails.ifsc }}</span>
                    <span class="text">{{
                      virtualAccountDetails.beneficiary
                    }}</span>
                  </div>
                </div>
                <span class="subtitle"
                  >Note: This account is a virtual escrow account belonging to
                  you, this is a safe and secure way of adding funds to your
                  wallet.</span
                >
                <span class="container-heading"
                  >Funds will be added your Finzace Wallet</span
                >
                <span class="subtitle"
                  >It can take upto 2 working day to reflect in your Finzace
                  Balance.</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Withdraw Money" [aria-label]="'WITHDRAW_MONEY'">
      <div class="tab-section">
        <div class="content-section">
          <div class="d-flex flex-column">
            <div class="form-input">
              <div class="input-box">
                <input
                  matInput
                  type="number"
                  min="0"
                  placeholder="Enter Amount"
                  [(ngModel)]="withdrawAmount"
                />
              </div>
            </div>
            <div class="amount-btn-row">
              <button
                type="submit"
                class="amount-btn"
                matRipple
                (click)="withdrawAmount = 10000"
              >
                ₹10,000
              </button>
              <button
                type="submit"
                class="amount-btn"
                matRipple
                (click)="withdrawAmount = 50000"
              >
                ₹50,000
              </button>
              <button
                type="submit"
                class="amount-btn"
                matRipple
                (click)="withdrawAmount = 100000"
              >
                ₹1 Lakh
              </button>
            </div>
          </div>
          <button
            type="submit"
            [disabled]="loading || withdrawAmount <= 0"
            (click)="withdrawMoney()"
            class="add-or-withdraw-money-btn"
            matRipple
          >
            Withdraw Money
          </button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Transaction" [aria-label]="'Transaction'">
      <div
        class="tab-section"
        infiniteScroll
        [infiniteScrollDistance]="1.5"
        [scrollWindow]="false"
        [infiniteScrollThrottle]="550"
        (scrolled)="getTransaction()"
        *ngIf="transactions.length !== 0"
      >
        <div class="d-flex flex-column">
          <div
            class="transaction"
            *ngFor="let transaction of transactions; let i = index"
          >
            <div class="transaction-record">
              <div class="d-flex flex-row align-items-center gap-2">
                <img
                  [src]="
                    '../../../../assets/icon/' +
                    commonService.getTransactionIcon(transaction) +
                    '.svg'
                  "
                  class="transaction-icon"
                />
                <div class="d-flex flex-column">
                  <span class="transaction-status"
                    >{{ transaction.purpose | titlecase }}
                  </span>
                  <span class="transaction-sub-status">{{
                    commonService.getSmartSaverTransactionStatus(transaction)
                      | titlecase
                  }}</span>
                </div>
              </div>
              <div class="d-flex flex-column">
                <span class="transaction-amount"
                  ><span>{{
                    transaction.status === "DEBITED" ? "- " : "+ "
                  }}</span>
                  ₹{{ transaction.amount | number: "1.1-2" }}</span
                >
                <span class="transaction-sub-status align-self-end">{{
                  transaction.transaction_date | date: "y-MM-dd"
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="transaction" *ngIf="transactionLoading">
          <ngx-shimmer-loading
            [shape]="'rect'"
            [height]="'55px'"
            [width]="'100%'"
            class="mt-2"
          ></ngx-shimmer-loading>
          <ngx-shimmer-loading
            [shape]="'rect'"
            [height]="'55px'"
            [width]="'100%'"
            class="mt-2"
          ></ngx-shimmer-loading>
          <ngx-shimmer-loading
            [shape]="'rect'"
            [height]="'55px'"
            [width]="'100%'"
            class="mt-2"
          ></ngx-shimmer-loading>
        </div>
      </div>
      <div
        class="tab-section"
        *ngIf="!transactionLoading && transactions.length === 0"
      >
        <div
          class="d-flex flex-column justify-content-center align-items-center mt-5"
        >
          <img
            src="../../../../assets/image/no-support-request-found.svg"
            width="300"
            height="auto"
          />
          <span class="title mt-4">No Transaction</span>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
