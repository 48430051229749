import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { IGenericResponse } from "src/models/genericModels";
import { TestimonialListResponse } from "src/models/testimonialModels";

@Injectable({
  providedIn: "root",
})
export class TestimonialService {
  constructor(private http: HttpClient) {}

  addTestimonial(body: any): Observable<IGenericResponse> {
    const token = `Token ${localStorage.getItem("authToken")}`;
    const url = `${environment.baseUrl}apis/users/testimonial/v1/creation/`;
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: token }),
    };
    return this.http.post<IGenericResponse>(url, body, httpOptions);
  }

  fetchTestimonialList(
    offset: number,
    limit: number,
    userid: string
  ): Observable<TestimonialListResponse> {
    const url = `${environment.baseUrl}apis/users/testimonial/v1/?offset=${offset}&limit=${limit}&user_id=${userid}`;
    let httpOptions = {};
    return this.http.get<TestimonialListResponse>(url, httpOptions);
  }
}
