<div [formGroup]="interestForm">
  <div class="d-flex flex-column">
    <label>Name</label>
    <input
      type="text"
      formControlName="name"
      placeholder="Your name here ..."
      required
    />
  </div>
  <div class="d-flex flex-column mt-3">
    <label>Phone Number</label>
    <input
      type="text"
      formControlName="phone_number"
      placeholder="Your phone number here ..."
      required
      minlength="10"
      [ngClass]="{
        invalid:
          interestForm.controls['phone_number'].touched &&
          interestForm.controls['phone_number'].invalid
      }"
    />
    <span
      *ngIf="
        interestForm.controls['phone_number'].touched &&
        interestForm.controls['phone_number'].invalid
      "
      class="input-invalid"
      >Please enter valid phone number</span
    >
  </div>
  <button
    matRipple
    class="submit-btn"
    [disabled]="interestForm.invalid"
    (click)="submit()"
  >
    <mat-spinner *ngIf="loading" diameter="20" strokeWidth="2"></mat-spinner>
    <span *ngIf="!loading">Submit</span>
  </button>
</div>
