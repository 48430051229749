import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { UserInterestLoggedOutBody } from "src/models/featureFlag";
import { IGenericResponse } from "src/models/genericModels";

@Injectable({
  providedIn: "root",
})
export class UserInterestService {
  constructor(private http: HttpClient) {}

  addUserInterestLoggedIn(feature: string): Observable<IGenericResponse> {
    const token = `Token ${localStorage.getItem("authToken")}`;
    const url = `${environment.baseUrl}apis/users/interests/logged-in/v1/`;
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: token }),
    };
    return this.http.post<IGenericResponse>(url, { key: feature }, httpOptions);
  }

  addUserInterestLoggedOut(
    body: UserInterestLoggedOutBody
  ): Observable<IGenericResponse> {
    const url = `${environment.baseUrl}apis/users/interests/logged-out/v1/`;
    return this.http.post<IGenericResponse>(url, body, {});
  }
}
