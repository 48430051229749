<div class="modal-container">
  <img
    class="withdrawal-received-image"
    src="../../../../assets/image/smart_saver_withdraw.svg"
    alt="withdrawal request received"
  />
  <div class="title">Withdrawal Request Received</div>

  <button class="close-btn" matRipple (click)="closeModal()">
    <img
      class="vector"
      src="../../../../assets/image/vector.svg"
      alt="close-icon"
    />
  </button>
</div>
