<div class="modal-container">
  <div class="detail-section">
    <img
      class="smart-saver-image"
      src="../../../../assets/image/smart_saver_withdraw.svg"
      alt="smart-saver-withdraw"
    />
    <div class="detail-row" *ngIf="!request_withdraw_clicked">
      <span class="title">Amount Available To Withdraw</span>
      <span class="value"
        >₹ {{ data.available_to_withdraw | number: "1.2" }}</span
      >
    </div>
    <span class="input-header" *ngIf="!request_withdraw_clicked"
      >Enter Amount</span
    >
    <div class="input-row" *ngIf="!request_withdraw_clicked">
      <span class="rupee">₹</span>
      <input
        min="100"
        pattern="[0-9]"
        maxlength="6"
        [numericInput]="12"
        [(ngModel)]="amount"
        max="300000"
        (input)="onInputChange()"
        #amountInput="ngModel"
      />
    </div>
  </div>
  <div class="start-saving-footer" *ngIf="!request_withdraw_clicked">
    <img
      src="../../../../../assets/icon/info_icon_primary_blue.svg"
      alt="info_icon"
      class="info-icon"
    />
    <span class="footer-text"
      >Our NBFS and SEBI Registered partners take upto 3 days to generate
      interest on your investments.</span
    >
  </div>
  <button
    *ngIf="!request_withdraw_clicked"
    type="submit"
    class="submit-btn"
    matRipple
    [disabled]="!isAmountValid || data.available_to_withdraw < 100"
    (click)="requestWithdraw()"
  >
    Request Withdrawal
  </button>
  <div
    class="d-flex flex-column"
    *ngIf="request_withdraw_clicked && showWithdrawOption"
  >
    <span class="withdraw-option-title"
      >We Provide two Withdrawal Options.</span
    >
    <span class="process-withdraw-title">Process Withdrawal To:</span>
    <button
      type="submit"
      class="submit-btn"
      matRipple
      [disabled]="loading || !isAmountValid || data.available_to_withdraw < 100"
      (click)="withdrawInvestment(false)"
    >
      <mat-spinner *ngIf="loading"></mat-spinner>
      <span *ngIf="!loading">Bank Account</span>
    </button>
    <span class="or">OR</span>
    <button
      type="submit"
      class="submit-btn"
      matRipple
      [disabled]="loading || !isAmountValid || data.available_to_withdraw < 100"
      (click)="withdrawInvestment(true)"
    >
      <mat-spinner *ngIf="loading"></mat-spinner>
      <span *ngIf="!loading">Finzace Wallet</span>
    </button>
  </div>
</div>
